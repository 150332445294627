import React from 'react'
import { Link } from 'gatsby'
import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  EmailIcon,
} from 'react-share';

import logo from '../img/sarahgrand.svg'
import './Navbar.css'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentHoverColor: this.props.color,
      active: false,
      navBarActiveClass: '',
      showScrollMenu: false,
      mobile: false
    };
    this.currentColor = this.currentColor.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // nav = React.createRef();
  
  handleResize = () => {
    const width = window.innerWidth

    if (width < 1024 && !this.state.mobile) {
      this.setState({mobile: true})
    } else if (width >= 1024 && this.state.mobile) {
      this.setState({mobile: false, active: false, navBarActiveClass: ''})
    };
  };


  currentColor = (color) => {
    this.setState({
      currentHoverColor: color,
    })
  }
 
  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

 render() {
   return (
     <div>
      <div style={{background: '#ee5da1', padding: '9px 0 9px', position: 'fixed', zIndex: 100, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <button
          className={`unbutton navbar-burger burger ${this.state.navBarActiveClass}`}
          data-target="navMenu"
          style={{marginLeft: '9px', padding: 0}}
          onClick={() => this.toggleHamburger()}
        >
          <span style={{borderColor: '#FFF'}} />
          <span style={{borderColor: '#FFF'}} />
          <span style={{borderColor: '#FFF'}} />
        </button>
        <div className='container' style={{display: 'flex', justifyContent: 'flex-end'}}>
          <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/sarah.grand'><FacebookIcon logoFillColor='#FFF' iconBgStyle={{fill: '#ee5da1', marginLeft: 9}} size={36} /></a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/shopsarahgrand/'><img alt='instagram' src='../../img/instagram.svg' style={{width: 18, height: 18, margin: '9px 6px 0 6px', fill: '#fff'}} /></a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/sarah-grand/'><LinkedinIcon logoFillColor='#FFF' iconBgStyle={{fill: '#ee5da1', marginLeft: 9}} size={36} /></a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.pinterest.com/grand0992/'><PinterestIcon logoFillColor='#FFF' iconBgStyle={{fill: '#ee5da1', marginLeft: 9}} size={36} /></a>
          <a target='_blank' rel='noopener noreferrer' href='sgrand624@gmail.com'><EmailIcon logoFillColor='#FFF' iconBgStyle={{fill: '#ee5da1', marginLeft: 9}} size={36} /></a>
        </div>
      </div>
      <nav className='navbar' role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand" style={this.state.mobile ? {justifyContent: 'space-around'} : null}>
            <Link to="/" title="Logo">
              <img src={logo} className='hover-tilt' alt="SG!" style={{marginLeft: '18px', marginRight: '18px', height: '320px', width: '320px' }} />
            </Link>            
          </div>
          <div 
            id="navMenu" 
            className={`navbar-menu ${this.state.navBarActiveClass} ${this.state.active ?  'fixed-menu' : null}`}
          >
            {this.state.active ? 
              <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <Link to="/" title="Logo">
                  <img src={logo} className='hover-tilt' alt="SG!" style={{marginTop: '72px', marginLeft: '18px', marginRight: '18px', height: '240px', width: '320px' }} />
                </Link>
              </div>
            : null}
            <div className="navbar-end has-text-centered">
              <Link 
                className="navbar-item navbar-teal" 
                activeStyle={{backgroundColor: '#f2f2f2', filter: 'opacity(0.6)', pointerEvents: 'none'}}
                style={this.state.active ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                } : {
                  display: 'block'
                }}
                to="/prints"
              >
                <img alt="Card Icon" src='../../img/prints.png' style={{height: this.state.active ? 40 : 100, width: this.state.active ? 40 : 100, borderRadius: '100%', border: `${this.state.active ? 3 : 6}px solid #00a79d`, marginRight: this.state.active ? 18 : 0}} />
                <p>Prints</p>
              </Link>
              <Link 
                className="navbar-item navbar-yellow" 
                activeStyle={{backgroundColor: '#f2f2f2', filter: 'opacity(0.6)', pointerEvents: 'none'}}
                to="/fashion"
                style={this.state.active ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                } : {
                  display: 'block'
                }}
              >
                <img alt="Dress Icon" src='../../img/fashion.jpg' style={{height: this.state.active ? 40 : 100, width: this.state.active ? 40 : 100, borderRadius: '100%', border: `${this.state.active ? 3 : 6}px solid #fff200`, marginRight: this.state.active ? 18 : 0}} />
                <p>Fashion</p>
              </Link>
              <Link 
                className="navbar-item navbar-pink" 
                activeStyle={{backgroundColor: '#f2f2f2', filter: 'opacity(0.6)', pointerEvents: 'none'}}
                to="/treats"
                style={this.state.active ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                } : {
                  display: 'block'
                }}
              >
                <img alt="Cake Icon" src='../../img/treats.jpg' style={{height: this.state.active ? 40 : 100, width: this.state.active ? 40 : 100, borderRadius: '100%', border: `${this.state.active ? 3 : 6}px solid #ebcae1`, marginRight: this.state.active ? 18 : 0}} />
                <p>Treats</p>
              </Link>
              <Link 
                className="navbar-item navbar-green" 
                activeStyle={{backgroundColor: '#f2f2f2', filter: 'opacity(0.6)', pointerEvents: 'none'}}
                to="/about"
                style={this.state.active ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                } : {
                  display: 'block'
                }}
              >
                <img alt="Head Icon" src='../../img/about.jpg' style={{height: this.state.active ? 40 : 100, width: this.state.active ? 40 : 100, borderRadius: '100%', border: `${this.state.active ? 3 : 6}px solid #79c143`, marginRight: this.state.active ? 18 : 0}} />
                <p>About</p>
              </Link>
              <a 
                className="navbar-item navbar-blue" 
                activeStyle={{backgroundColor: '#f2f2f2', filter: 'opacity(0.6)', pointerEvents: 'none'}}
                href="https://www.etsy.com/shop/SarahGrandDesigns"
                target="blank"
                style={this.state.active ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                } : {
                  display: 'block'
                }}
              >
                <img alt="Purse Icon" src='../../img/shop.png' style={{height: this.state.active ? 40 : 100, width: this.state.active ? 40 : 100, borderRadius: '100%', border: `${this.state.active ? 3 : 6}px solid #00aeef`, marginRight: this.state.active ? 18 : 0}} />
                <p>Shop</p>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )}
}

export default Navbar
