import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm = () => {

  const [email, setEmail] = useState('');
  const [isComplete, setComplete] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
    .then(() => {
      setComplete(true);
    })
    .catch((err) => {
      alert(err);
    });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      {isComplete ?
        <h3 style={{textAlign: 'center', color: 'rgba(255,255,255,0.8)'}}>Thanks for subscribing!</h3>
      :
        <div style={{display: 'block', textAlign: 'center'}}>
          <input
            placeholder="Email address"
            name="email"
            type="text"
            style={{padding: 9, fontSize: '112%', width: '100%', display: 'block', margin: '0 auto', maxWidth: '400px', height: '36px', borderRadius: '4px', border: 'none', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}
            onChange={handleEmailChange}
          />
          <button style={{border: 'none', marginTop: 24, backgroundColor: '#FFF', cursor: 'pointer'}} type="submit">Subscribe</button>
        </div>
      }
    </form>
  );
};

export default EmailListForm;