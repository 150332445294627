import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import logo from '../img/sarahgrand.svg'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    maxWidth: 420,
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
    border: 'none',
    backgroundColor: 'rgb(238, 93, 161)',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    textAlign: 'center'
  }
};
const SubscribeModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if(!localStorage.getItem('Viewed Subscribe Modal')) {
      setTimeout(() => setIsModalOpen(true), 7777)
    }
  })

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('Viewed Subscribe Modal', true)
  }


  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Stay Inspired!"
      closeTimeoutMS={2000}
      htmlOpenClassName='ReactModal__Html--open'
    >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 16}}>
        <img src={logo} className='hover-tilt' alt="SG!" style={{height: '84px', margin: '9px auto 0 auto'}} />
        <button className='unbutton' style={{color: '#FFF', fontSize: '400%', margin: 0, textAlign: 'right', padding: 0, lineHeight: 0, marginTop: 6}} onClick={closeModal}>&times;</button>
      </div>
      <h2 style={{fontSize: '125%', color: '#FFF', marginTop: 18}}>Stay Inspired!</h2>
      <p style={{marginBottom: 18, fontSize: '125%', color: '#FFF'}}>Sign up to get notified when I publish a new post and get 20% off your first order!</p>
      {props.children}
      <button className='unbutton' style={{color: '#FFF', textDecoration: 'underline', marginTop: 9, cursor: 'pointer'}} onClick={closeModal}>No Thanks</button>
    </Modal>
  );
};
export default SubscribeModal;