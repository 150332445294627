import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"

import logo from '../img/sarahgrand.svg'
import Navbar from '../components/Navbar'
import './all.sass'
import EmailListForm from './EmailListForm'
import SubscribeModal from './SubscribeModal'

const TemplateWrapper = ({ color, children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />

          <link rel="stylesheet" href="https://use.typekit.net/tgo2mlq.css" />

	        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ee5da1" />
	        <meta name="theme-color" content="#fff" />

	        <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="/" />
        </Helmet>
        <Navbar color={color} />
        <div className={`body-bottom-${color}`}>{children}</div>
        <div style={{ background: '#ee5da1', color: '#fff', textAlign: 'center', paddingTop: '12px'}}>
          <div style={{margin: '24px 0 24px 0'}}>
            <Link className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} to='/'>Home</Link>
            <Link className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} to='/prints'>Prints</Link>
            <Link className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} to='/fashion'>Fashion</Link>
            <Link className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} to='/treats'>Treats</Link>
            <Link className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} to='/about'>About</Link>
            <a className='footer-link' style={{margin: '0 18px', padding: '18px 0', color: '#FFF'}} href='https://www.etsy.com/shop/SarahGrandDesigns' target="_blank" rel="noopener noreferrer">Shop</a>
          </div>
          <Link style={{margin: '0 18px'}} to="/" title="Logo">
            <img src={logo} className='hover-tilt' alt="SG!" style={{height: '72px', widt: '72px' }} />
          </Link>
          <p style={{padding: 9}}>Made by Sarah & Bert</p>
        </div>
        <SubscribeModal>
          <EmailListForm />
        </SubscribeModal>
      </div>
    )}
  />
)

export default TemplateWrapper
